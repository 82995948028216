import { Avatar, Divider, Rating } from '@mui/material'
import React from 'react'

const NewTestimonial = () => {

  const testimonialData = [
    {
      name: "Uday Kumar",
      Designation: "Senior Software Engineer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
      imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
    },
    {
      name: "Uday Kumar",
      Designation: "Senior Software Engineer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
      imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
    },
    {
      name: "Uday Kumar",
      Designation: "Senior Software Engineer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
      imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
    },
    {
      name: "Uday Kumar",
      Designation: "Senior Software Engineer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
      imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
    },
    {
      name: "Uday Kumar",
      Designation: "Senior Software Engineer",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
      imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
    }
  ]
  return (
    <div>
      <p className='text-center text-2xl'>Testimonial</p>
      <h3 className='text-center text-3xl'>We are always happy to hear from our clients.</h3>
      <div className='flex justify-center gap-4 mt-10 flex-wrap'>
        {
          testimonialData.map((data) => (
            <div className='border border-[#F9FAFB] h-[340px] rounded-2xl w-[250px] p-[20px] flex flex-col items-center gap-1 bg-[#F9FAFB]'>
              <Avatar
                alt={data.name}
                src="https://media.licdn.com/dms/image/v2/D5603AQEUvZ-zbLV0Qw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1713983449133?e=1732147200&v=beta&t=78f37lsVo-M8p__axz_5ue-6kJ48OTMCLoBKkpxCbco"
                sx={{ width: 60, height: 60,margin:"10px 0px" }}
              />
              <Divider variant="middle" sx={{ width: "80%" }} />
              <h1 className='font-semibold'>{data.name}</h1>
              <p className='text-center text-[#4f46e5] text-sm'>{data.Designation}</p>
              <Rating sx={{margin:"10px 0px"}} name="read-only" value={5} readOnly />
              <p className='text-center text-md'>{data.desc}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default NewTestimonial