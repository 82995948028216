import React from 'react'

const MyTestimonial = () => {
    const data1 = [
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/5.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/1.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem? ",
            imgUrl: "https://www.hover.dev/imgs/head-shots/6.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/2.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/9.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/3.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/4.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/7.jpg"
        },
        {
            name: "Name",
            Designation: "Designation",
            desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, autem?",
            imgUrl: "https://www.hover.dev/imgs/head-shots/8.jpg"
        },
    ]
    return (
        <div className='w-full my-2 ' id='testimonial'>
            <h1 className='text-center my-4 text-3xl font-bold text-textColor'>My Testimonials</h1>
            <div className='overflow-hidden flex flex-col items-center gap-4 w-full'>
                <div className='flex gap-4 min-w-[500px] -translate-x-48 animate-slide-left my-2'>
                    {data1.map((item) => (
                        <div className='flex max-w-[500px] min-w-[500px] iPhoneSE:min-w-[400px] iPhoneSE:max-w-[400px]  laptop:min-w-[500px] laptop:max-w-[500px] justify-between bg-[#0F172A] rounded-lg h-[150px]'>
                            <img className='max-w-[150px] object-cover max-h-[190px] relative rounded-lg' src={item?.imgUrl} alt="profile" />
                            <div className='p-4 flex flex-col gap-[5px] justify-center'>
                                <h1 className='text-white font-semibold'>{item?.name}</h1>
                                <p className='text-white font-semibold'>{item?.Designation}</p>
                                <p className='text-[#818A99]'>{item?.desc}</p>
                            </div>
                        </div>

                    ))}
                </div>
                <div className='flex gap-4 min-w-[500px] -translate-x-100 animate-slide-right'>
                    {data1.map((item) => (
                        <div className='flex max-w-[500px] min-w-[500px] iPhoneSE:min-w-[400px] iPhoneSE:max-w-[400px]  laptop:min-w-[500px] laptop:max-w-[500px] justify-between bg-[#0F172A] rounded-lg h-[150px]'>
                            <img className='max-w-[150px] object-cover max-h-[190px] relative rounded-lg' src={item?.imgUrl} alt="profile" />
                            <div className='p-4 flex flex-col gap-[5px] justify-center'>
                                <h1 className='text-white font-semibold'>{item?.name}</h1>
                                <p className='text-white font-semibold'>{item?.Designation}</p>
                                <p className='text-[#818A99]'>{item?.desc}</p>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default MyTestimonial